import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

export default function SectionDivider({ title, headingSize = 3, ...props }) {
  return (
    <Row className="mb-3">
      {title ? (
        <Col xs="auto">
          <p className={`h${headingSize} font-weight-bold`} {...props}>
            {title}
          </p>
        </Col>
      ) : null}
      <Col className="d-flex align-items-center w-100">
        <hr className="w-100 bg-muted" />
      </Col>
    </Row>
  )
}
