import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Seo from "../components/Seo"
import SectionDivider from "../components/SectionDivider"
import { ExternalLink } from "../components/ui/ExternalLink"

export const Head = () => {
  return <Seo customTitle="Impressum" />
}

export default function Impressum() {
  const data = useStaticQuery(graphql`
    query Impressum {
      site {
        siteMetadata {
          email
          titleFull
          locationStreet
          locationTown
          locationZIP
          contactPersons {
            person
            title
          }
        }
      }
      markdownRemark(frontmatter: { title: { eq: "Impressum" } }) {
        html
      }
    }
  `)

  const {
    email,
    titleFull,
    locationStreet,
    locationTown,
    locationZIP,
    contactPersons,
  } = data.site.siteMetadata

  const text = data.markdownRemark.html

  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Impressum" />
        <address className="h4">
          <h2 className="h3 font-weight-bold mb-3">{titleFull}</h2>
          {locationStreet}
          <span className="d-block mt-2 mb-3">
            {locationZIP} {locationTown}
          </span>
          <ExternalLink className="h5" href={`mailto:${email}`}>
            {email}
          </ExternalLink>
        </address>

        <SectionDivider />

        <dl className="row">
          <dt className="col-12 col-md-4 col-lg-5">
            Verantwortlich i.S.d. § 55 Abs. 2 RStV
          </dt>
          <dd className="col">
            {contactPersons[0].person}, {contactPersons[0].title} der{" "}
            {titleFull}
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-12 col-md-4 col-lg-5">Vereinssitz</dt>
          <dd className="col">
            {locationStreet}, {locationZIP} {locationTown}
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-12 col-md-4 col-lg-5">Registergericht</dt>
          <dd className="col">Amtsgericht Plettenberg, VR 1715</dd>
        </dl>

        <div
          className="mt-5 lh-text"
          id="impressum"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </Container>
    </Layout>
  )
}
